<template>
  <div class="ticket" v-if="$route.path === '/businessModule/merchant/configure'">
    <d-search
      :searchData="searchData"
      @searchEvent="searchEvent"
      @resetEvent="resetEvent"
    />
    <div class="content">
      <div class="header">
        <el-button type="primary" size="small" @click="addFun">新增罚金类型</el-button>
      </div>
      <d-lable class="contentTable"
              :tableData='tableData'
              :columnData='columnData'>
        <template v-slot:updateUser="{ row }">
          <span>{{ row.updateUser && row.updateUser.username }}</span>
        </template>
        <template v-slot:action="{ row }">
          <span class="mgr10 blue" v-if="!row.penalTypeId" type="text" @click="addItemFun(row)">新增罚金项</span> 
          <span class="mgr10 blue"  type="text" @click="editFun(row)">编辑</span> 
          <span class="mgr10 blue" type="text" @click="deleteFun(row)">删除</span> 
        </template>
      </d-lable>
    </div>
    <d-paging :pager="pager"
              @change="getItemList" />
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="cancelFun">
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
          <template>
            <el-form-item label="罚金类型：" prop="typeName">
              <el-input v-model="form.typeName" size="small" placeholder="请输入" maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </template>
          <el-form-item label="备注：">
            <el-input v-model="form.remark" maxlength="50" show-word-limit size="small" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="cancelFun">取 消</el-button>
        <el-button type="primary" @click="confirmFun">确 定</el-button>
      </div>
    </el-dialog>
  </div>
  <router-view v-else/>
</template>

<script>
import DSearch from "@/components/d-search";
import DLable from "@/components/d-table";
import DPaging from "@/components/d-paging";
export default {
  name: "Ticket",
  components: {
    DSearch,
    DLable,
    DPaging,
  },
  data() {
    return {
      searchData: {
        searchItem: [
          {
            label: "罚金类型",
            value: "typeName",
            type: "commonInput",
            placeholder: "模糊搜索"
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
        },
        params: {
          typeName: ''
        },
      },
      columnData: [
        { prop: 'typeName', label: '罚金类型' },
        { prop: 'remark', label: '备注' },
        { prop: 'updateTime', label: '更新时间' },
        { prop: 'updateUser', label: '更新人员', slotProp: true },
        { prop: 'action', label: '操作', slotProp: true },
      ],
      tableData: [],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      dialogVisible: false,
      title: '新增罚金类型',
      form: {
        typeName: '',
        remark: ''
      },
      rules: {
        typeName: [{ required: true, message: '请输入', trigger: 'blur' }],
      }
    };
  },
  created () {
    this.getItemList()
  },
  methods: {
    searchEvent() {
      this.pager.page = 1
      this.pager.rows = 10
      this.getItemList()
    },
    resetEvent() {
      this.getItemList()
    },
    addFun () {
      this.setModalData('新增罚金类型')
    },
    addItemFun (row) {
      this.$router.push({ path: '/businessModule/merchant/configure/item',query: { id: row.id } })
    },
    editFun ({ typeName, remark, id }) {
      this.form = { id, remark, typeName }
      this.setModalData('修改罚金类型')
    },
    setModalData (title) {
      this.title = title
      this.dialogVisible = true
    },
    deleteFun ({ id }) {
      this.$confirm('删除罚金类型会删除此类型下所有的罚金项，请确认是否删除？','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning'
      }).then(()=>{
        this.$api.merchant.disableMerchantTickApi({ id }).then(({ code, msg }) => {
          if(code == 200) {
            this.$message.success(msg)
            this.getItemList()
            return
          }
          this.$message.error(msg)
        })
      }).catch(() => {})
    },
    cancelFun () {
      this.form = {
        typeName: '',
        remark: ''
      }
      this.$refs.form && this.$refs.form.clearValidate()
      this.dialogVisible = false
    },
    confirmFun () {
      this.$refs.form.validate(valid => {
        if(valid) {
          const { typeName, remark, id } = this.form
          const Api = id ? 'updateMerchantTickApi' : 'addMerchantTickApi'
          const data = { id, typeName, remark }
          this.$api.merchant[Api](data).then(({ code, msg }) => {
            if(code !== 200) {
              return this.$message.error(msg)
            }
            this.cancelFun()
            this.$message.success(msg)
            this.$nextTick(() => {
              this.getItemList()

            })
          })
        }
      })
    },
    getItemList () {
      const data = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        typeName: this.searchData.params.typeName || ''
      }
      this.$api.merchant.getPenalTypesApi(data).then(({ code, data}) => {
        if(code !== 200) return
        this.pager.count = data.total || 0
        this.tableData = data.list || []
      })
    },
  },
};
</script>

<style scoped lang='scss'>
.ticket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 30px 30px;
  margin-top: -20px;
  box-sizing: border-box;
  width: 100%;
  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
  }
  .el-table {
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
  }
}
.d-paging {
  text-align: center;
}
</style>